<template>
  <div>
      <div
      class="container-fluid bgColorSoftGrey paddingServiceSection pt-2">
      <div class="container marginTopServiceOpen">


            <!-- Open service 5 -->
            <div class="column is-12" data-aos="zoom-in" >

        
                <p class="fontWeightBlack fontNoSoBlack servicesFont has-text-white mt-5 mb-5 fontUpperCase has-text-centered titleServices">
                  Seguro de vida
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 has-text-centered colorTertiary lineHeight1 textAlignResponsiveJustify2" >
                  Contamos con una amplia trayectoria en reclamos administrativos y judiciales relacionados a Seguro de Enfermedad y Vida para distintos empleados de la administración pública.
                  Brindamos asesoramiento MÉDICO-JURÍDICO a:
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 textAlignResponsiveJustify2 lineHeight1" >
                  DOCENTES - POLICÍAS DE LA PROVINCIA DE BS. AS. - POLICÍA FEDERAL ARGENTINA- JUDICIALES - EMPLEADOS DE ARBA -MINISTERIO DE SALUD - MINISTERIO DE ECONOMÍA - YPF - PENITENCIARIO DE BS. AS. - GENDARMERÍA - ENTRE OTROS.            </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 has-text-white textAlignResponsive subtitleServices" >
                  Requisitos
                </p>
                <p class="has-text-left title is-4 lineHeight2">
                  1- Tener el descuento de primas en el recibo de sueldo.<br>
                  2- Tener HASTA 64 AÑOS DE EDAD.<br>
                  3- Tener el cese laboral o jubilación y que no haya pasado más de un año (período de prescripción para con contrato de seguros).<br>
                  4- Enfermedades que dificulten su actividad.
                </p>
                <p class="has-text-right title is-4">
                  Para mayor información <span class="is-hidden-tablet"><br><br></span> <a href="#contacto" class="buttonContacto has-text-dark p-3">Contacto</a>
                </p>
                
            </div>

        </div>
      </div>

    <Footer />
    <Whatsapp  />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Whatsapp from '@/components/Whatsapp'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,
      service7: false
      }
  },
  components: {
    Footer,
    Whatsapp
  },
  mounted () {
      this.goTop()
  },
  computed: {
    ...mapState(['serviceOpen'])
  },
  methods: {
    ...mapActions(['setServiceOpen']),
    setService7 () {
      this.service7 ? this.service7 = false : this.service7 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    goTop() {
        console.log('GoTop')
        window.scrollTo(0, 0)
    }
  }
}
</script>

<style>

</style>
