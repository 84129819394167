<template>
  <div>
      <div
      class="container-fluid bgColorSoftGrey paddingServiceSection pt-2">
      <div class="container marginTopServiceOpen">

            <!-- Open service 7 -->
            <div class="column is-12" data-aos="zoom-in">

                <p class="fontWeightBlack fontNoSoBlack servicesFont has-text-white mt-5 mb-5 fontUpperCase has-text-centered titleServices">
                  Sucesiones
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 has-text-centered colorTertiary lineHeight1 textAlignResponsiveJustify2" >
                  En caso de fallecimiento del causante, el estudio ofrece para aquellos que poseen derecho a suceder, el inicio del proceso sucesorio.
                  Desde la apertura hasta la declaratoria de herederos o desde la apertura hasta la finalización.
               </p>
                <p class="fontWeightBlack title is-4 mb-2 pt-4 pl-4 textAlignResponsiveJustify2 lineHeight1" >
                  ¿Quiénes tienen derecho a suceder? - Sucesión Ab-intestato.
                 </p>
                 <p class="fontWeightBlack title is-4 pl-4 textAlignResponsiveJustify2 lineHeight1" >
                  - Descendientes.<br>
                  - Ascendientes.<br>
                  - Cónyuge supérstite.<br>
                  - Parientes colaterales en el orden establecido por el CCyCN.<br>
                </p><br>
                 <p class="fontWeightBlack title  mb-2 is-4 pt-4 pl-4 textAlignResponsiveJustify2 lineHeight1" >
                  ¿Qué documentación necesito?
                 </p>
                 <p class="fontWeightBlack title is-4 pt-4 pl-4  textAlignResponsiveJustify2 lineHeight1" >
                  - Nombre, domicilio, DNI, estado civil de todos los que heredan.<br>
                  - Documentación que pruebe el vínculo.<br>
                  - En relación a los bienes: títulos de propiedad, boleta de ABL o ARBA del año en curso.<br>
                  - Otra documentación que acredite cuentas bancarias, derechos, acciones, etc.<br>
                </p><br><br>               
                <p class="has-text-right title is-4">
                  Para mayor información <span class="is-hidden-tablet"><br><br></span> <a href="#contacto" class="buttonContacto has-text-dark p-3">Contacto</a>
                </p>
                
            </div>

        </div>
      </div>

    <Footer />
    <Whatsapp  />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Whatsapp from '@/components/Whatsapp'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,
      service7: false
      }
  },
  components: {
    Footer,
    Whatsapp
  },
  mounted () {
      this.goTop()
  },
  computed: {
    ...mapState(['serviceOpen'])
  },
  methods: {
    ...mapActions(['setServiceOpen']),
    setService7 () {
      this.service7 ? this.service7 = false : this.service7 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    goTop() {
        console.log('GoTop')
        window.scrollTo(0, 0)
    }
  }
}
</script>

<style>

</style>
