<template>
  <div>
      <div
      class="container-fluid bgColorSoftGrey paddingServiceSection pt-2">
      <div class="container marginTopServiceOpen">

           <div class="column is-12" data-aos="zoom-in">

                <p class="fontWeightBlack fontNoSoBlack servicesFont has-text-white mt-5 mb-5 fontUpperCase has-text-centered titleServices">
                  Jubilaciones
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 has-text-centered colorTertiary has-text-centered" >
                  Jubilación Docente: digital IPS / DIEGEP / ANSES - Jubilación automática docente - Jubilación por incapacidad -Jubilación por edad Avanzada.
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 textAlignResponsiveJustify2 " >
                BeronAbogados es un estudio jurídico que ofrece un servicio integral docente y asesoramiento en otras áreas de práctica.
        Como especialistas en jubilaciones brindamos un análisis de cada prestación previsional, sean servicios puros o mixtos;  continuidad; cargo de mayor jerarquía; licencias, entre otros.
        Nos caracteriza la excelencia, el compromiso y la responsabilidad con cada caso. Así como también la comunicación, acompañamiento y contención. 
        Ser parte de esta etapa tan importante para el docente dignifica nuestra profesión.  
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 has-text-white textAlignResponsive subtitleServices" >
                  Requisitos - IPS
                </p>
                <p class="has-text-left title is-4 lineHeight2">
-25 años de servicios y 50 años de edad.
<br>
-28 años de servicios y 53 años de edad.
<br>
-30 años de servicios y 55 años de edad.
                </p>
                <p class="has-text-right title is-4">
                  Para mayor información <span class="is-hidden-tablet"><br><br></span> <a href="#contacto" class="buttonContacto has-text-dark p-3">Contacto</a>
                </p>
                
            </div>


        </div>
      </div>

    <Footer />
    <Whatsapp  />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Whatsapp from '@/components/Whatsapp'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,
      service7: false
      }
  },
  components: {
    Footer,
    Whatsapp
  },
  mounted () {
      this.goTop()
  },
  computed: {
    ...mapState(['serviceOpen'])
  },
  methods: {
    ...mapActions(['setServiceOpen']),
    setService7 () {
      this.service7 ? this.service7 = false : this.service7 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    goTop() {
        console.log('GoTop')
        window.scrollTo(0, 0)
    }
  }
}
</script>

<style>

</style>
