<template>
  <div>
      <div
      class="container-fluid bgColorSoftGrey paddingServiceSection pt-2">
      <div class="container marginTopServiceOpen">

        <!-- Open service 4 -->
            <div class="column is-12" data-aos="zoom-in" >

                <p class="fontWeightBlack fontNoSoBlack servicesFont has-text-white mt-5 mb-5 fontUpperCase has-text-centered titleServices">
                  Pensión
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 has-text-centered colorTertiary  has-text-centered" >
                 Pensión Directa - Pensión Derivada.
                 <br>
                 Pensión Automática Docente - Pensión Automática Derivada.
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 textAlignResponsiveJustify2" >
                  BeronAbogados ofrece a los futuros beneficiarios con derecho a Pensión el asesoramiento, armado de doumentación,
                  ingreso de solicitud, así también, agilización en el otorgamiento del beneficio.

                </p>
                <p class="fontWeightBlack is-size-4-tablet is-size-4-mobile has-text-white
                subtitleServices3 pt-4 pl-2 pr-2 lineHeight1 textAlignResponsive" >
                  ¿Quiénes tienen derecho a la pensión?
                </p>
                <p class="has-text-left title is-4 lineHeight2">
                  - Viuda/o, la conviviente o el conviviente.
                  <br>
                  - Hijos solteros, hijas solteras e hijas viudas.
                  <br>
                  - Nietos solteros, nietas solteras y nietas viudas.
                  <br>
                  - Padres incapacitados (a cargo del causante).
                  <br>
                  - Hermanos solteros, hermanas solteras y hermanas viudas (todos ellos huérfanos).
                </p>
                <p class="has-text-right title is-4">
                  Para mayor información <span class="is-hidden-tablet"><br><br></span> <a href="#contacto" class="buttonContacto has-text-dark p-3">Contacto</a>
                </p>
                
            </div>

        </div>
      </div>

    <Footer />
    <Whatsapp  />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Whatsapp from '@/components/Whatsapp'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,
      service7: false
      }
  },
  components: {
    Footer,
    Whatsapp
  },
  mounted () {
      this.goTop()
  },
  computed: {
    ...mapState(['serviceOpen'])
  },
  methods: {
    ...mapActions(['setServiceOpen']),
    setService7 () {
      this.service7 ? this.service7 = false : this.service7 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    goTop() {
        console.log('GoTop')
        window.scrollTo(0, 0)
    }
  }
}
</script>

<style>

</style>
