<template>
  <div>
      <div
      class="container-fluid bgColorSoftGrey paddingServiceSection pt-2">
      <div class="container marginTopServiceOpen">


            <div class="column is-12" data-aos="zoom-in" >


                <p class="fontWeightBlack fontNoSoBlack servicesFont has-text-white mt-5 mb-5 fontUpperCase has-text-centered titleServices">
                  Reclamos y reajustes
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 has-text-centered colorTertiary textAlignResponsiveJustify2" >
                 RECLAMOS: Reclamos por Actualización de Cargos Nacionales - Recursos de Revocatoria - Reclamos por errores en la
                 liquidación del haber - Agregar acto administrativo de cese en Jubilaciones por Cierre de cómputo - Incorporación
                 de certificaciones de alumno regular.
                 <br><br>
                 REAJUSTES: Es un pedido fundado en la existencia de servicios documentados (Certificación o Reconocimiento) que se suman a los abrantes en el expediente
                 principal, con el fin de que el instituto modifique la forma en que bona un beneficio otorgado.
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 textAlignResponsiveJustify2" >
                  Tanto en casos de reclamos como en reajustes, se analizará la viabilidad del pedido, el armado de la documentación necesaria
                  y obligatoria para el inicio del trámite junto con la agilización de su resolución.
                </p>
            </div>

        </div>
      </div>

     <Footer />
    <Whatsapp  />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Whatsapp from '@/components/Whatsapp'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,
      service7: false
      }
  },
  components: {
    Footer,
    Whatsapp
  },
  mounted () {
      this.goTop()
  },
  computed: {
    ...mapState(['serviceOpen'])
  },
  methods: {
    ...mapActions(['setServiceOpen']),
    setService7 () {
      this.service7 ? this.service7 = false : this.service7 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    goTop() {
        console.log('GoTop')
        window.scrollTo(0, 0)
    }
  }
}
</script>

<style>

</style>
