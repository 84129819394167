<template>
  <div>
      <div
      class="container-fluid bgColorSoftGrey paddingServiceSection pt-2">
      <div class="container marginTopServiceOpen">

            <!-- Open service 7 -->
            <div class="column is-12" data-aos="zoom-in">

                <p class="fontWeightBlack fontNoSoBlack servicesFont has-text-white mt-5 mb-5 fontUpperCase has-text-centered titleServices2">
                  Art-Accidentes y enfermedades - Despidos
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 has-text-centered colorTertiary lineHeight1 has-text-centered" >
                  Enfermedades profesionales: Disfonía - Várices - Hernias - Accidente / in itinere - Despidos.
                </p>
                <p class="fontWeightBlack title is-4 pt-4 pl-4 lineHeight1 textAlignResponsiveJustify2 lineHeight1" >
                 Brindamos asesoramiento a aquellos trabajadores que padecen enfermedades laborales tales como disfonía, várices, hernias,
                 como así también, a aquellos que han sufrido un accidente en el trabajo, en camino o de regreso al mismo.
                 <br>
                 Contamos con Médico Legista, Unidad de traslado y un grupo de profesionales especializado en accidentes, enfermedades y conflictos laborales.
                 <br>
                 <br>
                 Entrevista ONLINE y PRESENCIAL adaptables a la necesidad del cliente.
                </p>

                <p class="has-text-right title is-4">
                  Para mayor información <span class="is-hidden-tablet"><br><br></span> <a href="#contacto" class="buttonContacto has-text-dark p-3">Contacto</a>
                </p>
                
            </div>

        </div>
      </div>
    <Footer />
    <Whatsapp  />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Whatsapp from '@/components/Whatsapp'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,
      service7: false
      }
  },
  components: {
    Footer,
    Whatsapp
  },
  mounted () {
      this.goTop()
  },
  computed: {
    ...mapState(['serviceOpen'])
  },
  methods: {
    ...mapActions(['setServiceOpen']),
    setService7 () {
      this.service7 ? this.service7 = false : this.service7 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    goTop() {
        console.log('GoTop')
        window.scrollTo(0, 0)
    }
  }
}
</script>

<style>

</style>
